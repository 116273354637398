$color-primary: #C7F9F4;
$color-secondary: #000;

@import "base";
@import "components/news_items";

header[role=banner] .logo {
  margin-left: scaling-size(0.5, 1.5, rem);
}

.banner-bg-invest {
  background: $color-primary;
  flex: none;
}

.banner-bg-primary {
  background-color: $color-primary;
}

.box-primary {
  background-color: $color-primary;
}

.card--primary-background {
  @extend .background\:barossa-sky-blue-100;
}
